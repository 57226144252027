import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [agreed, setAgreed] = useState(false)

  return (
    <>
    <div className="isolate bg-[#1c1c24] border-opacity-30  text-white border-t-[1px] border-[#808191] font-epilogue py-12 px-6 sm:py-16 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Ready to revolutionize your app development process?</h2>
        <p className="mt-2 text-lg leading-8 text-gray-400">
        Sign up now and start building your next project with Workbench!
        </p>
      </div>
      <form onSubmit="https://go.tryworkbench.com" className="mx-auto mt-4 max-w-xl sm:mt-5">
        <div>
          <a href="https://go.tryworkbench.com" className="block w-full rounded-md bg-emerald-600 px-3.5 py-12 text-center text-xl font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Start Building Now →
          </a>
        </div>
      </form>
    </div>
    </>
    )
};