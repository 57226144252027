import * as React from "react";
import { Container, Heading, VStack } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import Banner from "./components/Banner";
import Video from "./components/Video";
import Cta from "./components/Cta.js";
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Logo from './logo.png'

const products = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]
  
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
  <Banner />
  <header className="bg-[#13131a] border-b-[1px] border-gray-700 border-opacity-100 font-epilogue">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1 mt-[-15px]">
          <a href="#" className="">
            <span className="sr-only">Workbench</span>
            <div className="flex flex-row">
            <img className="h-20 w-auto" src={Logo} alt="" />
            <p className="text-white mt-[32px] text-2xl font-bold">Workbench</p>
            </div>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <a href="#demo" className="text-sm font-semibold leading-6 text-white">
            Demo
          </a>
          <a href="#features" className="text-sm font-semibold leading-6 text-white">
            Features
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
             <a href="https://go.tryworkbench.com" class="inline-flex justify-center items-center 
             py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-emerald-600
              hover:bg-emerald-500 focus:ring-4 focus:ring-emerald-300">
                Get Started →
            </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} 
      onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 
        w-full overflow-y-auto bg-[#13131a] px-6 py-6 sm:max-w-sm sm:ring-1
         sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Workbench</span>
              <img
                className="h-8 w-auto"
                src={Logo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="#demo"
                  className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Demo
                </a>
                <a
                  href="#features"
                  className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Features
                </a>
              </div>
              <div className="">
              <a href="https://go.tryworkbench.com" class="inline-flex justify-center items-center py-3 
              px-5 text-base font-medium text-center text-white rounded-lg bg-emerald-700
               hover:bg-emerald-800 focus:ring-4 focus:ring-sky-300 dark:focus:ring-sky-900">
                Get Started →
            </a>
            
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  <Hero />
  <div id="demo" />
  <Video />
  <Features/>
  <Cta />
  
<footer class="bg-[#13131a] border-t-[1px] pb-10 border-gray-800 font-epilogue flex-col flex">
    <div class="mx-auto w-full max-w-screen-xl p-4 flex flex-col">
        <div class="flex flex-col mx-auto">
          <div class="mb-6 md:mb-0 mr-8">
              <a href="#" class="flex items-center">
                  <img src={Logo} class="h-20 mr-[2px]" alt="Workbench Logo" />
                  <span class="self-center text-2xl mt-[20px] font-semibold 
                  whitespace-nowrap text-white">Workbench</span>
              </a>
          </div>
      </div>
      <div class="flex items-center flex flex-col mt-4">
          <span class="text-sm sm:text-center text-gray-400">© 2023 <a href="#" class="hover:underline">Workbench</a>. All Rights Reserved.
          </span>
 
      </div>
    </div>
</footer>

    </>
  );
}

export default App;
