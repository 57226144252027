import video from './assets/demo-video-compressed-no-audio.mp4'

export default function Example() {
    return (
      <div id="#demo" className="bg-[#1c1c24] font-epilogue border-opacity-100 border-t-[1px] border-b-[1px] border-gray-800">
        <div className="mx-auto max-w-7xl py-12 sm:px-6 sm:py-16 lg:px-8 text-center ">
        <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none 
        md:text-3xl lg:text-4xl text-white">See Workbench in Action</h1>
        <p class="mb-8 text-md font-normal sm:px-16 xl:px-48 text-gray-400">Watch our video demonstration to witness the future of app development.</p>
        <video class="w-[80%] mx-auto rounded-[15px]" autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
          </div>
        </div>
    )
  }
  