import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faCode, faLaptop, faObjectGroup } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
  return (
<section class="bg-[#1c1c24]">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 text-white">
  <div id="features" />
      <div class="max-w-screen-md mb-8 lg:mb-16 mx-auto text-center">
          <h2 class="mb-4 text-2xl md:text-3xl lg:text-4xl tracking-tight font-extrabold text-white">Discover the Power of AI-Driven App Development</h2>
          <p class="text-md font-normal text-gray-400">Unlock a new era of efficiency and innovation with our game-changing features</p>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 sm:pr-48 sm:pl-48 lg:grid-cols-2 md:gap-12 md:space-y-0 text-center">
          <div>
              <div class="flex justify-center items-center mx-auto mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-primary-900">
                <FontAwesomeIcon icon={faProjectDiagram} />
              </div>
              <h3 class="mb-2 text-xl font-bold text-white">Intuitive AI-Powered Design</h3>
              <p class="text-gray-400">Create visually stunning and responsive user interfaces with our AI-driven design system.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mx-auto mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-primary-900">
                <FontAwesomeIcon icon={faCode} />
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Code Generation</h3>
              <p class="text-gray-400">Automatically generate high-quality, clean code for web and mobile platforms, saving you time and effort.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mx-auto mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                <FontAwesomeIcon icon={faLaptop} />
              </div>
              <h3 class="mb-2 text-xl font-bold text-white">Cross-Platform Compatibility</h3>
              <p class="text-gray-400">Build apps that work seamlessly on iOS, Android, and the web, all from a single codebase.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mx-auto mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 bg-primary-900">
                <FontAwesomeIcon icon={faObjectGroup} />
              </div>
              <h3 class="mb-2 text-xl font-bold text-white">Customizable Templates</h3>
              <p class="text-gray-400">Choose from a wide range of pre-built templates and customize them to fit your unique requirements.</p>
          </div>
          </div>
  </div>
</section>
  );
};

export default Features;
