import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Banner() {
  return (
    <div className="relative isolate border-b-[1px] border-opacity-100 border-gray-700 
    flex items-center gap-x-6 overflow-hidden bg-gray-800 py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
      <div className="flex flex-wrap items-center gap-y-2 gap-x-4">
        <p className="text-xs leading-6 text-white opacity-80">
          <strong className="font-semibold">Workbench Plugin</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Our GPT Plugin is en-route. More will be announced soon.
        </p>
      </div>
      <div className="flex flex-1 justify-end">
      </div>
    </div>
  )
}
